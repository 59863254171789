import React from 'react';
import Layout from '../components/layout';
import { Helmet } from "react-helmet";
import TopBlueSection from '../components/TopBlueSection';
import BasicText from '../components/BasicText';
import Glossary from '../components/Glossary';
import TwoColumnTexts from '../components/TwoColumnTexts';
import FAQs from '../components/FAQs';

export default ({pageContext, ...props}) => (
    <Layout className="full-width-layout">
    
    <Helmet
        htmlAttributes={{
        lang: 'en',
        }}
    >
        {pageContext.noindex_page === 'noindex' &&
        <meta name="robots" content="noindex, follow"></meta>
        }
        <meta charSet="utf-8" />
        <title>MyRetirement | {pageContext.title}</title>
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="c71e0e36-b367-4b3d-a1ba-3f53aed3fc7b-test" ></script>
        <script type="text/javascript">
        function OptanonWrapper() { }
        </script>
    </Helmet>
   
    {pageContext.default_page_modular_content &&
        <div className="page-content">
            {pageContext.default_page_modular_content.map((section, i) => {
                const layoutName = section.site_module;
                switch (layoutName) {
                  case "top_blue_section":
                        return(
                            <TopBlueSection 
                                key={`mc-${i}`}
                                wrapperClass="site-container"
                                pageTitle={pageContext.title}
                                sectionText={section.section_copy}
                                anchorLinkCols={section.anchor_link_columns}
                            />
                        )
                  case "basic_text":
                      return(
                          <BasicText 
                            key={`mc-${i}`}
                            wrapperClass="site-container"
                            sectionText={section.basic_text_section_text}
                            sectionID={section.text_section_id}
                            icon={section.basic_text_optional_icon}
                            linksArray={section.basic_text_optional_links}
                            tcColumns={section.tc_image_and_text_column}
                            displayLinks={section.display_links}
                          />
                      )
                    case "two_column_wysiwygs":
                    return(
                        <TwoColumnTexts 
                            key={`mc-${i}`}
                            leftCol={section.left_column_wysiwygs}
                            rightCol={section.right_column_wysiwygs}
                        />
                    )
                    case "glossary_listing":
                    return(
                        <Glossary
                            key={`mc-${i}`} 
                        />
                    )
                    case "faqs_listing":
                    return(
                        <FAQs
                            key={`mc-${i}`}
                        />    
                    )
                  
                default:
                    // Do nothing
                }  return null;
              })
              
            }
        </div>
    }
    </Layout>
);