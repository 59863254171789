import React from 'react';
import {graphql, StaticQuery} from 'gatsby';

import FAQItem from './FAQItem';

class FAQs extends React.Component {
   

    

    render(){
        return(
            <section className="faqs-listing">
                
                    <StaticQuery
                    query={graphql`
                    {
                        allFaqsJson {
                          nodes {
                            content 
                            title 
                            faqs_categories
                            id
                            faq_question
                          }
                        }
                        allFaqCategoriesJson {
                          nodes {
                            id
                            name
                            slug
                            faq_category_title
                          }
                        }
                      }
                    `}
                    render={props => (
                        <div className="site-container">
                                {props.allFaqCategoriesJson.nodes.map((item) => {
                                  //  console.log(props.allFaqCategoriesJson);
                                    let groupID = parseInt(item.id);
                                    let groupTitle=item.name;
                                    if(item.faq_category_title){
                                      groupTitle=item.faq_category_title;
                                    }
                                    let groupItems = props.allFaqsJson.nodes.filter(function (e) {
                                        return e.faqs_categories[0] === groupID;
                                    });
                                    return(
                                    <div className="faq-group"  key={`faq-group-${item.id}`} id={`${item.slug}`} >
                                        <h2 className="group-title" dangerouslySetInnerHTML={{__html: groupTitle}} />
                                        {groupItems.map((gItem) => {
                                          let faqQuestion = '';
                                          if(gItem.faq_question){
                                            faqQuestion = gItem.faq_question;
                                          } else{
                                            faqQuestion = gItem.title;
                                          }
                                            return(
                                                <FAQItem 
                                                    key={`faq-entry-${gItem.id}`}
                                                    question={faqQuestion}
                                                    answer={gItem.content}
                                                />
                                            
                                            )
                                        })}
                                    </div>
                                    )
                                })}
                            </div>
                            
                    )}
                    />    
            </section>
        )
    }

}

export default FAQs;