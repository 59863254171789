import React from 'react';

const TwoColumnTexts = (props) => {
   // console.log(props.leftCol);
    return(
        <section className="two-column-texts">
            <div className="site-container">
                <div className="section-columns">
                    <div className="left-col">
                         {props.leftCol.map((item, i) =>{
                            return(
                                <div className="text-item" key={`text-item-${i}`}>
                                    <div dangerouslySetInnerHTML={{__html: item.left_column_wysiwyg}} />
                                </div>
                            )
                         })}
                    </div>
                    <div className="right-col">
                        {props.rightCol.map((item, i) =>{
                            return(
                                <div className="text-item" key={`text-item-${i}`}>
                                    <div dangerouslySetInnerHTML={{__html: item.right_column_wysiwyg}} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TwoColumnTexts;