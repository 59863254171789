import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
class Glossary extends React.Component {
    constructor () {
        super()
        this.state = { activeCat : "A" }
        this.handleClick = this.handleClick.bind(this)      
      }
    
      handleClick(letter) {
        this.setState({
          activeCat: letter
        })
      }

    render(){
        const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
        return(
            <section className="glossary-listing">
                <div className="site-container">
                    <StaticQuery
                        query={graphql`
                        {
                            allGlossaryTermsJson {
                                edges {
                                node {
                                    id
                                    title 
                                    content
                                    glossary_categories
                                }
                                }
                            }
                            allGlossaryCategoriesJson {
                                nodes {
                                id
                                name
                                }
                            }
                            }
                        `}
                        render={props => (
                            <div className="section-columns">
                                <div className="left-col">
                                    {props.allGlossaryCategoriesJson.nodes.map((item) => {
                                        let groupID = parseInt(item.id);
                                        let groupItems = props.allGlossaryTermsJson.edges.filter(function (e) {
                                            return e.node.glossary_categories[0] === groupID;
                                        });
                                        return(
                                        <div className="glossary-group"  key={`glossary-group-${item.id}`} id={`glossary-group-${item.id}`} >
                                            {groupItems.map((gItem) => {
                                                
                                                return(
                                                <div className="glossary-entry"  key={`glossary-entry-${gItem.node.id}`} >
                                                    <h2 className="glossary-item-title" dangerouslySetInnerHTML={{ __html: gItem.node.title }}/>
                                                    <div className="glossary-item-def"  dangerouslySetInnerHTML={{ __html: gItem.node.content}} />
                                                </div>
                                                )
                                            })}
                                        </div>
                                        )
                                    })}
                                </div>
                                <div className="right-col">
                                    <div className="glossary-group-anchors">
                                    {alphabet.map((letter, i)=> {
                                        let alphaLetter = letter.toUpperCase();
                                        let withLink = false;
                                        props.allGlossaryCategoriesJson.nodes.map((item) =>{
                                            let glossaryCat = item.name.toUpperCase();
                                            if(alphaLetter === glossaryCat){
                                                withLink = item.id;  
                                            } 
                                            return true;
                                        })
                                        if(withLink){
                                            return(
                                                <a 
                                                key={`anchor-${i}`} 
                                                className={(this.state.activeCat === alphaLetter) ? 'active' : ''}
                                                href={`#glossary-group-${withLink}`} 
                                                onClick={this.handleClick.bind(this, alphaLetter )}
                                                >   
                                                    <span>{alphaLetter}</span>
                                                </a>
                                            )
                                        }
                                        if(!withLink){
                                            return(
                                                <div key={`anchor-${i}`}>
                                                    <span>{alphaLetter}</span>
                                                </div>
                                            )
                                            
                                        } 
                                        return true;
                                    })}
                                    
                                
                                    </div>
                                </div>
                            </div>
                        )}
                        />    
                </div>
            </section>
        )
    }
    
}

export default Glossary;