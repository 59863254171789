import React from 'react';

class FAQItem extends React.Component {
    constructor () {
      super()
      this.state = { isActive: false }
      this.handleClick = this.handleClick.bind(this)      
    }

    
  
    handleClick() {
      this.setState({
        isActive: !this.state.isActive
      })
    }

    componentDidUpdate(){

        const answerTooltips = document.querySelectorAll('.faq-item-answer .glossary-term');
        answerTooltips.forEach(function (answerTooltip) {
          answerTooltip.addEventListener('mouseover', function hover(event) {
          var mousex = (event.clientX/window.innerWidth) * 100;    
          var mousey = (event.clientY/window.innerHeight) * 100;     
      
          for (var i = 0; i < answerTooltip.childNodes.length; i++) {
            if (answerTooltip.childNodes[i].className === "tooltip-item") {
             
              answerTooltip.childNodes[i].style.left = mousex + "%";
              answerTooltip.childNodes[i].style.top = mousey + "%";
              break;
            }        
        }
      
          if (mousex  >= 50){
            answerTooltip.classList.add('tooltip-left');
          } 
      
          if(mousey >= 50){
            answerTooltip.classList.add('tooltip-top');
          } else{
            answerTooltip.classList.add('tooltip-bottom');
          }
          answerTooltip.classList.add('show-tooltip');
        });
      
        answerTooltip.addEventListener('mouseout', function leave() {
          answerTooltip.classList.remove('show-tooltip', 'tooltip-left', 'tooltip-right', 'tooltip-top', 'tooltip-bottom');
        });
      
        window.addEventListener('scroll', event =>{ 
          answerTooltip.classList.remove('show-tooltip', 'tooltip-left', 'tooltip-right', 'tooltip-top', 'tooltip-bottom');
        });
      });
      // end tooltip User Events

        //Remove tooltips from elements with "no-tooltip" class
        const ttelems = document.querySelectorAll('.no-tooltip .tooltip-item');

        if(ttelems){
          ttelems.forEach(ttelem => {
            ttelem.parentNode.removeChild(ttelem);
          });
        }

        const nestedTooltips = document.querySelectorAll('.glossary-term .glossary-term .tooltip-item');

        if(nestedTooltips){
          nestedTooltips.forEach(ntt => {
            ntt.parentNode.removeChild(ntt);
          });
        }
    }

   
  
    render () {
  
      return (
        <div className={this.state.isActive ? 'faq-entry active' : 'faq-entry'}>
            <h2 className="question"><button onClick={this.handleClick} className="faq-item-question" aria-label="FAQ Toggle"><span className="button-interior" dangerouslySetInnerHTML={{__html:this.props.question}} /></button></h2> 
            {this.state.isActive &&    
            <div className="faq-item-answer"  dangerouslySetInnerHTML={{ __html: this.props.answer}} />
            }
        </div>
      )
    }
  }

  export default FAQItem;